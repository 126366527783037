import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { DateTime } from "luxon"
import { FaRegCalendarAlt, FaRegFilePdf, FaArrowRight } from "react-icons/fa"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"

import imgWinterWorkshop from "../images/resources-winter-workshop.jpg"
import imgSummerWorkshop from "../images/resources-summer-workshop.jpg"
import imgAdjudication from "../images/resources-adjudication.jpg"
import imgRecitals1 from "../images/photo-bar-5.jpg"
import imgRecitals2 from "../images/photo-bar-6.jpg"
import imgRecitals3 from "../images/photo-bar-7.jpg"
import imgRecitals4 from "../images/photo-bar-9.jpg"

import "./resources.styles.scss"

const contentful = require("contentful")
const contentfulClient = contentful.createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
})

const StudioResourcesPage = ({ location }) => {
  const [summerWorkshop, setSummerWorkshop] = useState(null)
  const [winterWorkshop, setWinterWorkshop] = useState(null)
  const [virtualWorkshop, setVirtualWorkshop] = useState(null)
  const [studioCalendar, setStudioCalendar] = useState(null)
  const [tenYearList, setTenYearList] = useState(null)

  useEffect(() => {
    contentfulClient
      .getEntries({
        content_type: "workshopPage",
        "fields.lessonsPage": "true",
      })
      .then(response => {
        setSummerWorkshop(
          response.items.filter(
            page => page.fields.slug === "summer-flute-workshop"
          )[0]
        )
        setWinterWorkshop(
          response.items.filter(
            page => page.fields.slug === "winter-flute-workshop"
          )[0]
        )
        setVirtualWorkshop(
          response.items.filter(
            page => page.fields.slug === "adjudication-festival"
          )[0]
        )
      })
      .catch(console.error)
  }, [])

  useEffect(() => {
    contentfulClient
      .getEntries({
        content_type: "link",
        "fields.id": "studio-calendar",
      })
      .then(response => {
        setStudioCalendar(response.items[0])
      })
      .catch(console.error)
  }, [])

  useEffect(() => {
    contentfulClient
      .getEntries({
        content_type: "link",
        "fields.id": "10-year-list",
      })
      .then(response => {
        setTenYearList(response.items[0])
      })
      .catch(console.error)
  }, [])

  return (
    <Layout className="resources" pathname={location.pathname}>
      <Seo
        title="Parasky Flute Studios Resources"
        description="Resources and information for Parasky Flute Studios students and parents."
      />
      <section className="hero hero--resources is-medium is-dark">
        <div className="container">
          <div className="hero-body is-flex-direction-column is-justify-content-center has-text-shadow">
            <h1 className="title is-size-2 is-size-1-desktop is-uppercase has-text-centered">
              Studio Resources
            </h1>
          </div>
        </div>
      </section>
      <section className="resources__intro">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-half-tablet is-one-third-widescreen is-flex is-flex-direction-column">
              <Link
                to="/winter-flute-workshop"
                className="workshop is-flex is-flex-direction-column is-flex-grow-1 is-75-percent-mobile-xl"
              >
                <figure className="image">
                  <img
                    src={imgWinterWorkshop}
                    alt="Winter Flute Workshop"
                    className="is-flex-grow-0"
                  />
                </figure>
                <div className="workshop__content is-flex-grow-1">
                  <div className="has-text-grey is-size-7 mt-3">
                    <FaRegCalendarAlt className="mr-2" />
                    {winterWorkshop
                      ? winterWorkshop.fields.date
                        ? DateTime.fromISO(winterWorkshop.fields.date).toFormat(
                            "dd LLLL yyyy"
                          )
                        : "Date TBD"
                      : "Date TBD"}
                  </div>
                  <h3 className="is-size-3-desktop is-size-4-touch mt-3">
                    {winterWorkshop ? winterWorkshop.fields.title : "Workshop"}
                  </h3>
                  <div className="is-size-6 mt-2">
                    {winterWorkshop ? winterWorkshop.fields.shortIntro : ""}
                  </div>
                </div>
              </Link>
            </div>
            <div className="column is-half-tablet is-one-third-widescreen is-flex is-flex-direction-column">
              <Link
                to="/summer-flute-workshop"
                className="workshop is-flex is-flex-direction-column is-flex-grow-1 is-75-percent-mobile-xl"
              >
                <figure className="image">
                  <img
                    src={imgSummerWorkshop}
                    alt="Summer Flute Workshop"
                    className="is-flex-grow-0"
                  />
                </figure>
                <div className="workshop__content is-flex-grow-1">
                  <div className="has-text-grey is-size-7 mt-3">
                    <FaRegCalendarAlt className="mr-2" />
                    {summerWorkshop
                      ? summerWorkshop.fields.date
                        ? DateTime.fromISO(summerWorkshop.fields.date).toFormat(
                            "dd LLLL yyyy"
                          )
                        : "Date TBD"
                      : "Date TBD"}
                  </div>
                  <h3 className="is-size-3-desktop is-size-4-touch mt-3">
                    {summerWorkshop ? summerWorkshop.fields.title : "Workshop"}
                  </h3>
                  <div className="is-size-6 mt-2">
                    {summerWorkshop ? summerWorkshop.fields.shortIntro : ""}
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="resources__actions has-background-primary-light py-6">
        <div className="container is-flex is-justify-content-center">
          <a
            disabled={studioCalendar ? false : true}
            href={
              studioCalendar ? studioCalendar.fields.file.fields.file.url : "#"
            }
            target="_blank"
            rel="noreferrer"
            className="button is-primary py-3 px-5 is-flex is-align-items-center is-size-5 button--rotate-pdf"
          >
            Studio Lesson Calendar <FaRegFilePdf className="ml-3" />
          </a>
        </div>
      </section>
      <section className="resources__recitals py-4">
        <div className="container">
          <div className="content is-size-6 container--text">
            <h2 className="is-size-2-desktop is-size-3-mobile">Recitals</h2>
            <p>
              Performing is an integral part of instruction so multiple
              performances are scheduled each season as a part of your flute
              lessons.
            </p>
            <p>
              All students perform in one formal solo recital annually but there
              are additional recitals scheduled as well. Flute ensemble
              participants will participate in a minimum of 3 concerts each
              year.
            </p>
            <p>
              Information about each recital is sent out with 8 weeks’ notice
              provided. A small additional fee is billed for the solo recitals
              to support pianist and rental fees.
            </p>
          </div>
          <div className="columns is-mobile is-multiline">
            <div className="column is-full-mobile is-half-tablet is-one-quarter-desktop">
              <figure className="image is-75-percent-mobile-xl">
                <img
                  src={imgRecitals1}
                  alt="Students performing"
                  className="has-border-radius-2 has-box-shadow"
                />
              </figure>
            </div>
            <div className="column is-full-mobile is-half-tablet is-one-quarter-desktop">
              <figure className="image is-75-percent-mobile-xl">
                <img
                  src={imgRecitals2}
                  alt="Students performing"
                  className="has-border-radius-2 has-box-shadow"
                />
              </figure>
            </div>
            <div className="column is-full-mobile is-half-tablet is-one-quarter-desktop">
              <figure className="image is-75-percent-mobile-xl">
                <img
                  src={imgRecitals3}
                  alt="Students performing"
                  className="has-border-radius-2 has-box-shadow"
                />
              </figure>
            </div>
            <div className="column is-full-mobile is-half-tablet is-one-quarter-desktop">
              <figure className="image is-75-percent-mobile-xl">
                <img
                  src={imgRecitals4}
                  alt="Students performing"
                  className="has-border-radius-2 has-box-shadow"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="about__videos py-6 has-background-primary-light">
        <div className="container py-6">
          <div className="columns content">
            <div className="column">
              <div className="video-container">
                <iframe
                  src="https://www.youtube.com/embed/hz4Jc2rAp7c"
                  title="Fairest of the Fair...Sousa (arr. Lombardo)"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="column">
              <div className="video-container">
                <iframe
                  src="https://www.youtube.com/embed/T-LLHiPK5iU?si=8kdQ_umtYzNQr948"
                  title="The Cat's Pajamas...Nicole Chamberlain"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="video-container">
                <iframe
                  src="https://www.youtube.com/embed/WVJpiWjlw-8?si=Ev9DEDCB_53EUERT"
                  title="Whirl (2016)...Gay Kahkonen (b. 1968)"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="column">
              <div className="video-container">
                <iframe
                  src="https://www.youtube.com/embed/v8b5mb3qEWA?si=wbR2tbQDo8ykvi8n"
                  title="2023 Halloween Concert Tour"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="resources__winners py-6">
        <div className="container">
          <div className="content is-size-6">
            <h2 className="is-size-2-desktop is-size-3-mobile">
              2022-2023 Season
            </h2>
          </div>
          <div className="columns mt-4">
            <div className="column is-one-third">
              <div className="content is-size-6 is-flex is-flex-direction-column">
                <h3 className="has-border-bottom-info-3 is-align-self-flex-start mt-5">
                  PMEA Events
                </h3>
                <h6 className="mt-2 mb-0">PMEA Honors Band</h6>
                <ul>
                  <li>AJ Schmidt, piccolo</li>
                  <li>Elsa Bandli, alternate</li>
                </ul>
              </div>
            </div>
            <div className="column is-one-third">
              <div className="content is-size-6 is-flex is-flex-direction-column">
                <h3 className="has-border-bottom-info-3 is-align-self-flex-start mt-5">
                  Ensemble Competitions
                </h3>
                <h6 className="mt-2 mb-0">
                  Pittsburgh Youth Symphony Orchestra
                </h6>
                <ul>
                  <li>Rhys Vander Kelen</li>
                  <li>AJ Schmidt</li>
                </ul>
                <h6 className="mt-2 mb-0">TRYPO Orchestra</h6>
                <ul>
                  <li>Nia Hanington</li>
                  <li>Elsa Bandli</li>
                </ul>
                <h6 className="mt-2 mb-0">TRYPO Wind Ensemble</h6>
                <ul>
                  <li>Vanessa Amayo</li>
                  <li>Ava Zannikos</li>
                </ul>
                <h6 className="mt-2 mb-0">
                  Mid-Atlantic High School Flute Choir
                </h6>
                <ul>
                  <li>AJ Schmidt</li>
                  <li>Rhys Vander Kelen</li>
                  <li>Nia Hanington</li>
                  <li>Elsa Bandli</li>
                </ul>
                <h6 className="mt-2 mb-0">
                  Slippery Rock Honors Flute Ensemble
                </h6>
                <ul>
                  <li>Mabel Buben</li>
                  <li>Ally Marion</li>
                  <li>laire Corr</li>
                  <li>Rhys Vander Kelen</li>
                </ul>
                <h6 className="mt-2 mb-0">Seton Hill Youth Honors Band</h6>
                <ul>
                  <li>Ahaana Ajmani</li>
                  <li>Eleanor Aganad</li>
                  <li>Delaney Liu</li>
                  <li>Kelsey McGee</li>
                  <li>Anjana Mandava</li>
                  <li>Alex Saccavini</li>
                  <li>Alex Schmidt</li>
                  <li>Ava Zannikos</li>
                </ul>
              </div>
            </div>
            <div className="column is-one-third">
              <div className="content is-size-6 is-flex is-flex-direction-column">
                <h3 className="has-border-bottom-info-3 is-align-self-flex-start mt-5">
                  Solo Competitions
                </h3>
                <h6 className="mt-2 mb-0">WSO Young Artist Competition</h6>
                <br />
                <span>Level I (grade 9 and under)</span>
                <ul>
                  <li>2nd place - Elsa Bandli</li>
                  <li>3rd place - Kaitlyn Lippman</li>
                </ul>
                <span>Level II (grades 10-12)</span>
                <ul>
                  <li>1st place - AJ Schmidt</li>
                  <li>2nd place - Rhys Vander Kelen</li>
                  <li>Honorable Mention - Nia Hanington</li>
                </ul>
                <h6 className="mt-2 mb-0">
                  Woodbridge Flute Choir Concerto Competition
                </h6>
                <ul>
                  <li>AJ Schmidt, winner</li>
                </ul>
                <h6 className="mt-2 mb-0">COFA Solo Competition</h6>
                <ul>
                  <li>AJ Schmidt, first place - Senior Division</li>
                  <li>Rhys Vander Kelen, third place - Senior Division</li>
                </ul>
                <h6 className="mt-2 mb-0">Pittsburgh Concert Society</h6>
                <ul>
                  <li>AJ Schmidt, winner</li>
                </ul>
                <h6 className="mt-2 mb-0">Flute Society of Greater Philadelphia</h6>
                <ul>
                  <li>Rhys Vander Kelen, first place - Young Artist Competition</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="winners__footer">
          <div className="container is-size-6 mt-4 pb-6 has-text-centered">
            <a
              disabled={tenYearList ? false : true}
              href={tenYearList ? tenYearList.fields.file.fields.file.url : "#"}
              className="button is-primary arrow-right-to-external"
              target="_blank"
              rel="noreferrer"
            >
              Past studio winners <FaArrowRight className="ml-3" />
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default StudioResourcesPage
